// Global styles
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap");
@import "./styles/variables.scss";

html,
body,
#root {
  height: 100%;
  overflow-x: hidden;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif !important;
  margin: 0;
  background-color: "white";
}

html {
  overflow: hidden;
}

hr {
  width: 100%;
  align-self: center;
  margin: 0.5rem 0 !important;
}

a {
  text-decoration: none !important;
}

.blackout {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 900;
  background-color: black;
  height: 100%;
  width: 100%;
  transition: opacity 0.5s ease-in-out;
}

.orange-btn {
  background-color: rgb(255, 170, 62);
  color: white;
  border-width: 0;
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 2px rgba(100, 100, 100, 0.25);
  font-weight: 500;
  font-size: 1.05rem;
  transition: background-color 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    background-color: rgb(255, 179, 80);
  }
}

.orange-btn-outline {
  background-color: white;
  color: rgb(255, 170, 62);;
  border-color: rgb(255, 170, 62);
  border-width: 0.1rem;
  border-style: solid;
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 2px rgba(100, 100, 100, 0.25);
  font-weight: 500;
  font-size: 1.05rem;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    background-color: rgb(255, 179, 80);
    color: white;
  }
}

.white-btn-outline {
  background-color: rgba(255, 255, 255, 0.1); 
  color: rgba(255, 255, 255, 1);
  border-color: rgba(255, 255, 255, 1);
  border-width: 0.1rem;
  border-style: solid;
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  font-weight: 500;
  font-size: 1.05rem;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    background-color: rgba(255, 255, 255, 1); 
    color: rgba(227, 150, 50, 1);
  }
}

.white-btn {
  background-color: white; 
  color: rgb(227, 150, 50);
  border-width: 0;
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 2px rgba(100, 100, 100, 0.25);
  font-weight: 500;
  font-size: 1.05rem;
  transition: background-color 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    background-color: rgb(250,250,250);
  }
}

.green-btn-outline {
  background-color: rgba(41, 179, 60, 0.1); 
  color: rgba(41, 179, 60, 1);
  border-color: rgba(41, 179, 60, 1);
  border-width: 0.1rem;
  border-style: solid;
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  font-weight: 500;
  font-size: 1.05rem;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    background-color: rgba(41, 179, 60, 1); 
    color: rgba(255, 255, 255, 1);
  }
}

.green-btn {
  background-color: rgb(41, 179, 60); 
  color: rgb(255, 255, 255);
  border-width: 0;
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  font-weight: 500;
  font-size: 1.05rem;
  transition: background-color 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    background-color: rgb(22, 192, 44); 
  }
}

.blue-btn-outline {
  background-color: rgba(35, 157, 206, 0.1); 
  color: rgba(35, 157, 206, 1);
  border-color: rgba(35, 157, 206, 1);
  border-width: 0.1rem;
  border-style: solid;
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  font-weight: 500;
  font-size: 1.05rem;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    background-color: rgba(35, 157, 206, 1);
    color: rgba(255, 255, 255, 1);
  }
}

.blue-btn {
  background-color: rgb(35, 157, 206); 
  color: rgb(255, 255, 255);
  border-width: 0;
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  font-weight: 500;
  font-size: 1.05rem;
  transition: background-color 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    background-color: rgb(62, 166, 207); 
  }
}

.rounder-btn {
  border-radius: 1.5rem;
  padding: 0.5rem 0.7rem;
  box-shadow: none;
}

.tag-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem 1rem;
  overflow-x: auto;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    display: none;
  }
}

.tag {
  background-color: map-get($map: $light-theme, $key: grayLight);
  color: map-get($map: $light-theme, $key: grayText);
  padding: 0.25rem 0.7rem;
  white-space: nowrap;
  border-radius: 1rem;
  border-style: none;
  // border-width: 1px;
  // border-color: map-get($map: $light-theme, $key: grayDark);
  // box-shadow: 0 0 1px map-get($map: $light-theme, $key: grayBoxShadow);

  transition: background-color 0.2s ease-in-out;
}

.selected {
  background-color: map-get($map: $light-theme, $key: grayDarkSelected);
}

.ignore-padding {
  margin: auto -1rem !important;
}

.only-mobile {
  display: auto;

  @media (min-width: 768px) {
    display: none;
  }
}

.gray-bar-loader {
  width: 120px;
  height: 20px;
  border-radius: 1rem;
  background: 
    linear-gradient(
      90deg, rgba(200, 200, 200, 0.0666666667) 33%, 
      rgba(200, 200, 200, 0.3333333333) 50%, 
      rgba(200, 200, 200, 0.0666666667) 66%) 
      #f2f2f2;
  background-size: 300% 100%;
  animation: l1 1s infinite linear;
}
@keyframes l1 {
  0% {background-position: right}
}


.spinner-loader {
  color: map-get($map: $light-theme, $key: grayDarkSelected);
  font-size: 10px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: mulShdSpin 1.3s infinite linear;
  transform: translateZ(0);
}
@keyframes mulShdSpin {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 
    2em -2em 0 0em, 3em 0 0 -1em, 
    2em 2em 0 -1em, 0 3em 0 -1em, 
    -2em 2em 0 -1em, -3em 0 0 -1em, 
    -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 
    3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, 
    -2em 2em 0 -1em, -3em 0 0 -1em, 
    -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 
    2em -2em 0 0, 3em 0 0 0.2em, 
    2em 2em 0 0, 0 3em 0 -1em, 
    -2em 2em 0 -1em, -3em 0 0 -1em, 
    -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
     3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, 
     -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
     3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, 
     -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
     3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, 
     -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 
    3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, 
    -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 
    3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, 
    -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}
  
.search-bar-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.search-bar {
  position: relative;
  width: 100%;
}

.search-bar-options {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  display: none;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 0 2px #ccc;
  border-top: none;
  border-radius: 0.5rem;
}

.showing {
  display: flex;
}

.search-bar-option {
  width: 100%;
  padding: 10px;
  background-color: transparent;
  border: none;
  text-align: left;
  cursor: pointer;
  border-bottom: solid 1px #ccc;
  color: map-get($map: $light-theme, $key: blackText);
}

.search-bar-option:hover {
  background-color: #f1f1f1;
}
