/* Container & Typography */
.container {
    min-height: 100vh;
    width: 100vw;
    background-color: white;
    font-family: 'Inter', sans-serif;
  }
  
  /* Header */
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 5rem;
  }
  
  .header-logo {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .logo {
    width: 2rem;
  }
  
  .header-text {
    font-weight: 800;
    font-size: 2rem;
    color: #FF801F;
    margin: 0;
  }
  
  .header-buttons {
    display: flex;
    gap: 1rem;
  }
  
  /* Desktop buttons */
  .header-button-white {
    background-color: white;
    border: 2px solid #FF801F;
    color: #FF801F;
    font-weight: 500;
    padding: 0.5rem 1rem;
    border-radius: 4rem;
    font-size: 1rem;
    text-decoration: none;
  }
  
  .header-button-orange {
    background-color: #FF801F;
    border: 2px solid #FF801F;
    color: white;
    font-weight: 500;
    padding: 0.5rem 1rem;
    border-radius: 4rem;
    font-size: 1rem;
    text-decoration: none;
  }
  
  /* Mobile icon buttons */
  .header-button-icon {
    padding: 0.5rem;
    text-decoration: none;
  }
  
  .icon {
    width: 2rem;
  }
  
  /* Content Section */
  .content-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5rem;
    padding: 0 5rem;
  }
  
  /* Text Section */
  .text-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 50%;
  }
  
  .heading {
    font-size: 4rem;
    color: #FF801F;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 2rem;
  }
  
  .sub-heading {
    font-size: 2rem;
    color: #FF801F;
    font-weight: bold;
    line-height: 1;
  }
  
  .sub-heading-margin {
    margin-bottom: 2rem;
  }
  
  .description {
    font-size: 1.5rem;
    color: #FF801F;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 2rem;
  }
  
  .button {
    background-color: #FF801F;
    color: white;
    padding: 1rem 2rem;
    border-radius: 4rem;
    font-size: 1.5rem;
    font-weight: bold;
    text-decoration: none;
  }
  
  /* Image Section */
  .image-section {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
  }
  
  .landing-image {
    width: 100%;
    max-height: 90vh;
    object-fit: contain;
  }
  
  /* Footer */
  .footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 2rem;
    margin: 5rem;
  }
  
  .footer-text {
    font-size: 0.75rem;
    color: black;
    font-weight: bold;
    text-align: center;
  }
  
  .footer-link {
    font-size: 0.75rem;
    color: black;
    text-decoration: none;
    font-weight: bold;
    text-align: center;
  }
  
  /* Responsive Utility Classes */
  .desktop-only {
    display: flex;
  }
  
  .mobile-only {
    display: none;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .content-wrapper {
      flex-direction: column;
      padding: 0 2rem;
      gap: 2rem;
    }
    .text-section,
    .image-section {
      width: 90%;
    }
    .button {
      font-size: 1rem;
      padding: 0.5rem 1.5rem;
    }
    .header {
      padding: 2rem 3rem;
    }
    .heading {
      font-size: 2rem;
    }
    .sub-heading {
      font-size: 1rem;
    }
    .description {
      font-size: 1rem;
    }
    .footer-text,
    .footer-link {
      font-size: 0.5rem;
    }
    /* Header adjustments */
    .desktop-only {
      display: none;
    }
    .mobile-only {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }
  